import { Injectable } from '@angular/core';
import { CookieService } from '@gorniv/ngx-universal';

@Injectable({
    providedIn: 'root'
})
export class ReferralsService {
    private referral_id: string = '';
    private referral_cookie_days_lifetime: number = 7;

    public constructor(private cookiesService: CookieService) {}

    public extractReferralIdFromUrlParams(url: string): string {
        const regex: RegExp = /([?&])r(=|)([\d]+)(&|$)/;
        const regexMatch: RegExpMatchArray | null = url.match(regex);

        if (!regexMatch) {
            this.referral_id = this.cookiesService.get('r');

            return url;
        }

        this.referral_id = regexMatch[3];

        url = url.replace(regex, (_match, p1, _p2, _p3, p4) => {
            if (p1 === '?' && p4 === '&') {
                return '?';
            } else if (p4 === '&') {
                return '&';
            } else if (p1 === '?') {
                return '';
            } else {
                return '';
            }
        });

        this.setReferralCookie();

        return url;
    }

    public setReferralId(refId: string): void {
        this.referral_id = refId;
        this.setReferralCookie();
    }

    public getReferralId(): string {
        return this.referral_id || this.cookiesService.get('r');
    }

    private setReferralCookie(): void {
        let expire_date: Date = new Date();
        expire_date.setDate(expire_date.getDate() + this.referral_cookie_days_lifetime);
        if (this.referral_id === this.cookiesService.get('r')) {
            return;
        }
        this.cookiesService.put('r', this.referral_id.toString(), {
            expires: expire_date
        });
    }
}
