import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecimalBySystemPipe } from './decimal-by-system.pipe';
import { SafeUrlPipe } from '@app/transactions/pages/transaction-info/transaction-operation-data/waiting-payment/waiting-payment-cupon/safe-url.pipe';
import { TruncateTextMiddlePipe } from '../../../ui/shared/pipes/truncate-text-midde.pipe';

@NgModule({
    declarations: [DecimalBySystemPipe, SafeUrlPipe, TruncateTextMiddlePipe],
    exports: [DecimalBySystemPipe, SafeUrlPipe, TruncateTextMiddlePipe],
    imports: [CommonModule]
})
export class PipesModule {}
