import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorPageComponent } from '@app/core/components/error-page/error-page.component';
import { RouteHandlerComponent } from '@app/core/components/route-handler/route-handler.component';
import { ReferralsGuard } from './referrals-guard.guard';

const routes: Routes = [
    {
        path: 'my',
        loadChildren: () => import('./my/my.module').then((m) => m.MyModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: 'http',
        loadChildren: () => import('./ssr-check/httpclient/http.module').then((m) => m.HttpModule)
    },
    {
        path: 'no-cache',
        loadChildren: () => import('./ssr-check/no-cache/no-cache.module').then((m) => m.NoCacheModule)
    },
    {
        path: 'no-cache-http',
        loadChildren: () => import('./ssr-check/no-cache-http/no-cache-http.module').then((m) => m.NoCacheHttpModule)
    },
    {
        path: 'no-cache-jsonapi',
        loadChildren: () => import('./ssr-check/no-cache-jsonapi/no-cache-jsonapi.module').then((m) => m.NoCacheJsonapiModule)
    },
    {
        path: ':langCode/my',
        loadChildren: () => import('./my/my.module').then((m) => m.MyModule)
    },
    {
        path: 'cobrar',
        loadChildren: () => import('./transactions/pages/exchange/exchange.module').then((m) => m.ExchangeModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: ':langCode/cobrar',
        loadChildren: () => import('./transactions/pages/exchange/exchange.module').then((m) => m.ExchangeModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: 't/transactions/v3',
        loadChildren: () => import('../ui/pages/transaction-details/transaction-details.module').then((m) => m.TransactionDetailsModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: 't/transactions',
        loadChildren: () => import('./transactions/pages/transaction-info/transaction-info.module').then((m) => m.TransactionInfoModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: ':langCode/t/transactions',
        loadChildren: () => import('./transactions/pages/transaction-info/transaction-info.module').then((m) => m.TransactionInfoModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: 'comprar-vcc',
        loadChildren: () => import('./transactions/pages/transaction-vcc/transaction-vcc.module').then((m) => m.TransactionVccModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: ':langCode/comprar-vcc',
        loadChildren: () => import('./transactions/pages/transaction-vcc/transaction-vcc.module').then((m) => m.TransactionVccModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: ':langCode/dolar-blue-bolivia',
        loadChildren: () => import('../ui/pages/landing-bob/landing-bob.module').then((m) => m.LandingBobModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: 'dolar-blue-bolivia',
        loadChildren: () => import('../ui/pages/landing-bob/landing-bob.module').then((m) => m.LandingBobModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: 'ayuda',
        loadChildren: () => import('./wiki/help/help-center/help-center.module').then((m) => m.HelpCenterModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: ':langCode/ayuda',
        loadChildren: () => import('./wiki/help/help-center/help-center.module').then((m) => m.HelpCenterModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: 'comprar-vender/:system',
        loadChildren: () => import('./landing-for-systems/landing-for-systems.module').then((m) => m.LandingForSystemsModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: ':langCode/comprar-vender/:system',
        loadChildren: () => import('./landing-for-systems/landing-for-systems.module').then((m) => m.LandingForSystemsModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: 'por-que-elegir-saldoar',
        loadChildren: () => import('./landing-social-proof/landing-social-proof.module').then((m) => m.LandingSocialProofModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: ':langCode/por-que-elegir-saldoar',
        loadChildren: () => import('./landing-social-proof/landing-social-proof.module').then((m) => m.LandingSocialProofModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: 'pedido-cancelado',
        loadChildren: () => import('./wiki/help/landing-recovery/landing-recovery.module').then((m) => m.LandingRecoveryModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: ':langCode/pedido-cancelado',
        loadChildren: () => import('./wiki/help/landing-recovery/landing-recovery.module').then((m) => m.LandingRecoveryModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: 'u',
        loadChildren: () => import('./transactions/pages/user-info/user-info.module').then((m) => m.UserInfoModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: ':langCode/u',
        loadChildren: () => import('./transactions/pages/user-info/user-info.module').then((m) => m.UserInfoModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: 'faq',
        loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: ':langCode/faq',
        loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: 'wiki',
        loadChildren: () => import('./wiki/wiki.module').then((m) => m.WikiModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: ':langCode/wiki',
        loadChildren: () => import('./wiki/wiki.module').then((m) => m.WikiModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: 'retirar-cobrar-paypal-a-pesos-argentina',
        loadChildren: () => import('./wiki/landing-marketing/landing-marketing.module').then((m) => m.LandingMarketingModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: ':langCode/retirar-cobrar-paypal-a-pesos-argentina',
        loadChildren: () => import('./wiki/landing-marketing/landing-marketing.module').then((m) => m.LandingMarketingModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: 'comprar-recargar-saldo-paypal-argentina',
        loadChildren: () => import('./wiki/landing-marketing/landing-marketing.module').then((m) => m.LandingMarketingModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: ':langCode/comprar-recargar-saldo-paypal-argentina',
        loadChildren: () => import('./wiki/landing-marketing/landing-marketing.module').then((m) => m.LandingMarketingModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: 'transaction-states/:token',
        loadChildren: () =>
            import('./transactions/pages/transaction-states/transaction-states.module').then((m) => m.TransactionStatesModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: 'transaction-states',
        loadChildren: () =>
            import('./transactions/pages/transaction-states/transaction-states.module').then((m) => m.TransactionStatesModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: ':langCode/transaction-states',
        loadChildren: () =>
            import('./transactions/pages/transaction-states/transaction-states.module').then((m) => m.TransactionStatesModule),
        canActivate: [ReferralsGuard]
    },
    {
        path: 'error',
        component: ErrorPageComponent
    },
    {
        path: '**',
        component: RouteHandlerComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload',
            initialNavigation: 'enabledBlocking',
            // for dark-title anchor link
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            scrollOffset: [0, 42],
            paramsInheritanceStrategy: 'always'
            // relativeLinkResolution: 'legacy'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
