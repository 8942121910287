import { PlatformBrowserService } from './../../core/services/platform-browser.service';
import { Bugsnag, Detail } from './app-error';
import { Injectable, ɵRuntimeError } from '@angular/core';
import { IJsonApiErrorDocument } from './jsonapi-error.interface';
import { AppError } from '../services/app-error';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ErrorConverterService {
    private readonly NO_CONNECTION_MESSAGE: string = 'Sin conexión a Internet.';
    private readonly APP_ERROR_MESSAGE: string = '¡Ups! Ha ocurrido un error desconocido en la aplicación.';
    private readonly SERVER_ERROR_MESSAGE: string = '¡Ups! Ha ocurrido un error desconocido en el servidor.';

    public constructor(private browserService: PlatformBrowserService) {}

    public getAppErrorFromJsonApi(error: IJsonApiErrorDocument): AppError {
        let app_error: AppError = new AppError();
        app_error.message = 'JsonApi error';
        app_error.original_error = error;
        app_error.render = true;

        error.errors.forEach((jsonapi_error): void => {
            let detail: Detail = new Detail();
            if (jsonapi_error.source?.attribute) {
                detail.attribute_name = jsonapi_error.source.attribute;
            }
            detail.message = jsonapi_error.detail ?? jsonapi_error.title ?? '';
            app_error.detail_bag.push(detail);
        });

        return app_error;
    }

    public getAppErrorFromError(error: Bugsnag): AppError {
        let app_error: AppError = new AppError();
        app_error.message = this.browserService.isBrowser && !navigator.onLine ? this.NO_CONNECTION_MESSAGE : this.APP_ERROR_MESSAGE;
        app_error.original_error = error;
        app_error.report = true;

        return app_error;
    }

    public getAppErrorFromHttpErrorResponse(error: HttpErrorResponse): AppError {
        let app_error: AppError = new AppError();
        app_error.original_error = error;
        if (error.status >= 500) {
            app_error.message = this.SERVER_ERROR_MESSAGE;
            app_error.render = true;
        } else {
            app_error.message = this.APP_ERROR_MESSAGE;
            app_error.render = true;
        }

        return app_error;
    }

    public getAppErrorFromRuntimeError(error: ɵRuntimeError): AppError {
        let app_error: AppError = new AppError();
        app_error.original_error = error;
        app_error.message = error.message;
        app_error.render = true;
        app_error.report = true;

        return app_error;
    }
}
