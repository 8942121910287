<button type="submit" disabled style="display: none" aria-hidden="true"></button>
<button class="submit-btn" id="submit-component" mat-button type="{{type}}" [disabled]="disabled"
    [ngClass]="{'prevent-click': loading}">
    <mat-icon *ngIf="loading">
        <mat-spinner diameter="20"></mat-spinner>
    </mat-icon>
    <ng-container *ngIf="withText">
        <ng-content></ng-content>
    </ng-container>
</button>
