import { ErrorHandler, Injectable, Injector, ɵRuntimeError } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AppError } from './services/app-error';
import { ErrorConverterService } from './services/error-converter.service';
import { ErrorRendererService } from './services/error-renderer.service';
import { ErrorReporterService } from './services/error-reporter.service';
import { IJsonApiErrorDocument } from './services/jsonapi-error.interface';
import { BaseException } from './base-exception';
import { ErrorTrustedService } from './services/error-trusted.service';

export interface IHttpJsonApiError {
    error: IJsonApiErrorDocument;
    status?: number;
}

@Injectable()
export class CustomErrorHandlerService extends ErrorHandler {
    public constructor(private injector: Injector, private errorTrustedService: ErrorTrustedService) {
        super();
    }

    public handleError(error: IJsonApiErrorDocument | HttpErrorResponse | BaseException | ɵRuntimeError): void {
        const errorService: ErrorConverterService = this.injector.get(ErrorConverterService);

        let app_error: AppError;
        if (this.isJsonApiError(error) && error.errors[0].title === 'El correo no ha sido validado') {
            console.warn('User not validate!', error);
            app_error = errorService.getAppErrorFromJsonApi(error);
        } else if (this.isHttpJsonApiError(error)) {
            app_error = errorService.getAppErrorFromJsonApi(error.error);
        } else if (this.isJsonApiError(error)) {
            app_error = errorService.getAppErrorFromJsonApi(error);
        } else if (this.isPromiseAndHttpJsonApiError(error)) {
            // Promise rejection wrapped by zone.js
            /* eslint-disable @typescript-eslint/dot-notation */
            app_error = errorService.getAppErrorFromJsonApi(error['rejection'].error);
            /* eslint-enable @typescript-eslint/dot-notation */
        } else if (error instanceof HttpErrorResponse) {
            app_error = errorService.getAppErrorFromHttpErrorResponse(error);
        } else if (error instanceof BaseException) {
            app_error = error.getAppError();
        } else if ('isTrusted' in error) {
            this.errorTrustedService.isError.next(true);
            let scriptError: string = JSON.stringify(error, ['message', 'arguments', 'type', 'name']);
            app_error = errorService.getAppErrorFromError(scriptError);
        } else if (error instanceof ɵRuntimeError) {
            app_error = errorService.getAppErrorFromRuntimeError(error);
        } else {
            console.warn('CustomHandler. Unhandled error:', error);
            app_error = errorService.getAppErrorFromError(error);
        }

        if (app_error.render) {
            const error_render: ErrorRendererService = this.injector.get(ErrorRendererService);
            error_render.render(app_error);
        }

        if (app_error.report) {
            const error_reporter: ErrorReporterService = this.injector.get(ErrorReporterService);
            error_reporter.report(app_error);
        }
    }

    private isJsonApiError(error: any): error is IJsonApiErrorDocument {
        return error && error.errors && Array.isArray(error.errors);
    }

    private isPromiseAndHttpJsonApiError(error: any): boolean {
        /* eslint-disable @typescript-eslint/dot-notation */
        return error['promise'] && error['rejection'] && this.isHttpJsonApiError(error['rejection']);
        /* eslint-enable @typescript-eslint/dot-notation */
    }

    private isHttpJsonApiError(error: any): error is IHttpJsonApiError {
        return error.error && error.error.errors && Array.isArray(error.error.errors);
    }
}
